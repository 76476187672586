// src/components/About.jsx
import React from 'react';

const About = () => (
  <div className='body-content'>
    <h1>¿Quienes somos?</h1>
    <br/>
    <article>
      <p>Somos un grupo de desarrolladores que queremos estar a la vanguardia...</p>
    </article>
  </div>
);

export default About;