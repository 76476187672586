import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import Home from './components/Home';
import About from './components/About';

const App = () => (
  <Router>
    <div className='row'>
      <img aria-label="tecolote logo image" className='menu-header-logo column-inline'></img>
      <nav className='menu-header column-inline'> 
        <ul className='menu-link-group row'>
          <li><Link to="/">Inicio</Link></li>
          <li><Link to="/about">Acerca</Link></li>
        </ul>
      </nav>

      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
      </Routes>
    </div>
  </Router>
);

export default App;