// src/components/Home.jsx
import React from 'react';

const Home = () => (
  <div className='body-content'>
    <h1>Bienvenidos</h1>
    <article>
      <p>Proximamente se pondra la informacion y actividades de esta pagina...</p>
      </article>
  </div>
);

export default Home;